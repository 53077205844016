import React from "react";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { Link } from "gatsby";

import Head from "../../components/head.js";
import Layout from "../../components/layout.js";

const Edward = () => {
  return (
    <Layout>
      <Head title="Edward Alvarez" />
      <h3>Edward Alvarez</h3>

      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        San Diego, CA poet
      </h4>
      <div className="poemContainerNoLineBreaks">
        <p>Cold to the touch, a metallic magic</p>

        <p>pours forth in subtle gleams when</p>

        <p>exposed to light. A hollow half-circle</p>

        <p>beaten smooth around the edges</p>

        <p>"Tings" like a bell when struck.</p>

        <p>I lean towards it</p>

        <p>to examine its purpose</p>

        <p>and my reflected face</p>

        <p>looks distant and ghostly golden. </p>

        <p>Its round shape and weighty</p>

        <p>consistency perhaps may mean</p>

        <p>it once saw stranger days as an</p>

        <p>elfin helmet or a beggar's bowl. </p>

        <p>I speculate it is made of brass. When</p>

        <p>the rim is rubbed it vibrates and throws</p>

        <p>off curious waves of invisible energy</p>

        <p>both felt and heard. Perhaps it is an</p>

        <p>instrument used to send one's soul</p>

        <p>to another dimension when held in an</p>

        <p>outstretched hand. I surmise it is as old</p>

        <p>as the sun itself. Perhaps it was a metal-</p>

        <p>worker's mistake, and passed through </p>

        <p>merchant fingers for centuries. The</p>

        <p>object of attention for many a glowing</p>

        <p>gaze, I suspect it could have been used</p>
      </div>
      <br />

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665047087/artnow/oneoffs/poem-min.jpg"
        alt="poem by Edward Alvarez"
      />

      <p>---------------------------------</p>

      <h3>Interviews on artnow.world:</h3>
      <p>
        <Link to="/artist/george-motz">George Motz</Link>
      </p>
      <p>
        <Link to="/artist/john-howard">John Howard</Link>
      </p>
      <p>
        <Link to="/artist/scott-hronich">Scott Hronich</Link>
      </p>
      <p>
        <Link to="/artist/teitur-magnusson">Teitur Magnússon</Link>
      </p>
    </Layout>
  );
};

export default Edward;
